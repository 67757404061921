import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-default.tsx";
import MdxMultiSection from '../../templates/mdx-multi-section.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const NavSecondary = makeShortcode("NavSecondary");
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxMultiSection;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <a id="courses"></a>
    <section className="main style1">
      <header className="small">
        <h2 {...{
          "id": "emrg-courses"
        }}>{`EMRG: Courses`}</h2>
        <p>{`The Embodied Media Research Group (EMRG) provides a creative research framework to support and enhance courses taught by `}<a parentName="p" {...{
            "href": "https://jc.embodied.net"
          }}><span className="text-nowrap">{`Professor John Crawford`}</span></a>{` and associated faculty at UC Irvine. These courses engage and challenge technology-savvy art makers, storytellers and designers, providing opportunities to enhance creativity and develop new skills in socially engaged artmaking, connected design and emergent media production.`}</p>
      </header>
      <div className="image filtered" data-position="center">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "2400px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "33.33333333333333%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHLYTIB/8QAFxABAQEBAAAAAAAAAAAAAAAAAgERAP/aAAgBAQABBQIszgoZc3//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAAARABD/2gAIAQEABj8CIM//xAAXEAEBAQEAAAAAAAAAAAAAAAABIQAR/9oACAEBAAE/IQuBeymNKd3c3//aAAwDAQACAAMAAAAQA9//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxAZ/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qh//EABoQAQADAQEBAAAAAAAAAAAAAAEAESExUZH/2gAIAQEAAT8QWr8qO/ZUUS24ruwsCBeeT//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "augmented reality hands 3x1",
              "title": "augmented reality hands 3x1",
              "src": "/static/92c423d5105b69d5f3ee41e29dc39aed/111a0/augmented-reality-hands_3x1.jpg",
              "srcSet": ["/static/92c423d5105b69d5f3ee41e29dc39aed/3ad8d/augmented-reality-hands_3x1.jpg 600w", "/static/92c423d5105b69d5f3ee41e29dc39aed/6c738/augmented-reality-hands_3x1.jpg 1200w", "/static/92c423d5105b69d5f3ee41e29dc39aed/111a0/augmented-reality-hands_3x1.jpg 2400w"],
              "sizes": "(max-width: 2400px) 100vw, 2400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </div>
      <div className="features">
        <section>
          <header className="small">
  <div className="box">
    <h3> Immersive Digital Art</h3>
    <b>Fall 2020</b>
  </div>
          </header>
          <p><strong parentName="p">{`Immersive Digital Art`}</strong>{` explores how artists and designers use interactive digital systems to create immersive media experiences. These are environments that use illusory and physical cues to give participants the impression that they are inside of an experience. Case studies show how theatre, visual art, music and dance play a central role in developing immersive digital media. Students create a speculative design proposal for their own immersive media project.`}</p>
        </section>
        <section>
          <header className="small">
  <div className="box">
    <h3>Dance & Video</h3>
    <b>Winter 2021</b>
  </div>
          </header>
          <p><strong parentName="p">{`Dance & Video`}</strong>{` is an introduction to video and computer technologies for dance, with a particular focus on performance representation. This is an experiential course including hands-on training in video production and editing. It surveys methods for integrating dance performance with the film medium, discusses related aesthetic and conceptual issues, and introduces current techniques and technologies for video and audio recording of dance performance.`}</p>
          <ul className="actions special">
  <li><a href="https://courses.embodied.net/dance-video-grad/" className="button small">Course Info</a></li>
          </ul>
        </section>
        <section>
          <header className="small">
  <div className="box">
    <h3>Motion Tracking for Performance</h3>
    <b>Spring 2021</b>
  </div>
          </header>
          <p><strong parentName="p">{`Motion Tracking for Performance`}</strong>{` is an introduction to motion tracking for intermedia performance, intended for dancers and other performing artists. Demonstrations, discussion and experiential exercises provide practical experience with motion tracking concepts and techniques. Students work on experimental projects that intersect arts, design and technology through collaborative multimodal creation, with a showcase at the end of the quarter.`}</p>
          <ul className="actions special">
  <li><a href="https://courses.embodied.net/motion-tracking/" className="button small">Course Info</a></li>
          </ul>
        </section>
        <section>
          <header className="small">
  <div className="box">
    <h3>Screendance</h3>
    <b>Spring 2021</b>
  </div>
          </header>
          <p><strong parentName="p">{`Screendance`}</strong>{` is an introduction to screendance for choreographers. Also known as `}<em parentName="p">{`dance film`}</em>{`, `}<em parentName="p">{`cinedance`}</em>{`, `}<em parentName="p">{`videodance`}</em>{` and/or `}<em parentName="p">{`dance for the camera`}</em>{`, screendance connects film (and filmmaking) with dance (and dancemaking) in an evolving hybrid performative practice. More than simply the recorded representation of a performance, screendance combines choreography and cinematography to create a new artifact that is a work of art in and of itself.`}</p>
          <ul className="actions special">
  <li><a href="https://courses.embodied.net/screendance/" className="button small">Course Info</a></li>
          </ul>
        </section>
        <section>
          <header className="small">
  <div className="box">
    <h3>Online Performance</h3>
    <b>Fall 2022</b>
  </div>
          </header>
          <p><strong parentName="p">{`Online Performance`}</strong>{` is an introduction to creating and presenting fully online live performances. With recent advances in smartphones, computers and online technologies, anyone with a social media account can produce compelling online performance events at low or no cost. This course goes beyond the basics to introduce a range of different live performance platforms and how to use them most effectively. It’s suitable for graduate and undergraduate students in any performing arts discipline.`}</p>
          <ul className="actions special">
  <li><a href="https://courses.embodied.net/online-performance/" className="button small">Course Info</a></li>
          </ul>
        </section>
      </div>
    </section>
    <section className="main style2">
  <footer>
    <div className="row aln-center gtr-50 gtr-uniform">
      <NavSecondary mdxType="NavSecondary" />
    </div>
    <div className="row">
      <div className="col-12 footnote">Image Credit: HQuality</div>
    </div>
  </footer>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      